<template>
	<main class="home">

		<section class="content-login">
			<div class="content-login-div">
				<div class="content-login-logo">
					<img class="img-fluid" src="@/assets/img/home/logo-eurest.png" alt="logo eurest"
						title="logo eurest" />
				</div>
				<form ref="form" @submit.prevent="handleSubmit" class="content-login-form">
					<div v-if="!mneumonic" class="content-input">
						<label>Cód. Empresa</label>
						<input type="text" name="unit" value="" required />
					</div>
					<div v-else>
						<input type="hidden" name="unit" :value="mneumonic" />
					</div>
					<div class="content-input">
						<label>Cód. Utilizador</label>
						<input type="text" name="login" value="" required />
					</div>
					<div class="content-input">
						<label>Password</label>
						<input type="password" name="password" value="" required />
					</div>
					<router-link v-if="!this.$route.params.unit" class="btn-recupery-password pt-3"
						:to="{ name: 'recover-pass' }">
						Esqueceu-se da password?
					</router-link>
					<router-link v-else class="btn-recupery-password"
						:to="{ name: 'recover-pass', params: { unit: this.$route.params.unit } }">
						Esqueceu-se da password?
					</router-link>

					<button type="submit" class="content-login-form-btn">
						Login
					</button>

					<small v-if="unitEmail" class="custom-warning text-center pt-4">Em caso de dificuldade no acesso à
						APPREST, utilize o
						email informando o seu Nome e Número de
						colaborador.</small>


					<div v-if="unitEmail" class="btn-recupery-password pt-1">
						<a class="custom-link" :href="'mailto:' + unitEmail">{{ unitEmail }}</a>
					</div>
				</form>
			</div>
		</section>

	</main>
</template>
<script>
import { mapActions } from 'vuex';

export default {
	methods: {
		...mapActions('User', ['login']),
		...mapActions('Unit', ['getUnitByMneumonic']),
		async handleSubmit() {
			let formData = this.$root.getAllData(this.$refs.form);

			if (this.$refs.form.checkValidity()) {
				try {
					const validLogin = await this.login(formData);
					if(validLogin) {
						sessionStorage.setItem('eurest_url_cartao', window.location.href);
						this.$router.push({ name: 'home' });
					
					}
				} catch (error) {
					console.error(error);
				}
			} else {
				this.$refs.form.reportValidity();
			}
		}
	},
	async mounted() {
		sessionStorage.removeItem('apprest_token');
		this.mneumonic = this.$route.params.unit;
		//pegando a unidade pelo codigo mneumonic, custom_name é o nome do field no db.
		if (this.mneumonic) {
			try {
				this.unitEmail = await this.getUnitByMneumonic({ custom_name: this.mneumonic });
			} catch (error) {
				console.error(error);
			}
		}
	},
	data() {
		return {
			mneumonic: null,
			unitEmail: null
		}
	}
}
</script>
<style>
.custom-link {
	text-decoration: none !important;
	color: white !important;

	text-shadow: 0 0 1px #000000;
}

.custom-warning {
	display: flex;
	justify-content: center;
	font-size: 11px;
	color: #ffffff !important;
	text-shadow: 0 0 1px #000000;
}
</style>