<template>
	<main class="home">

		<section class="content-login">
			<div class="content-login-div">
				<div class="content-login-logo">
					<img class="img-fluid" src="@/assets/img/home/logo-eurest.png" alt="logo eurest"
						title="logo eurest" />
				</div>
				<form ref="form" @submit.prevent="handleSubmit" class="content-login-form">
					<div v-if="!unit" class="content-input">
						<label>Cód. Empresa</label>
						<input type="text" name="unit" value="" required />
					</div>
					<div v-else>
						<input type="hidden" name="unit" :value="unit" />
					</div>
					<div class="content-input">
						<label>Número do Cartão</label>
						<input type="text" name="card" value="" required />
					</div>
					<button type="submit" class="content-login-form-btn">
						Entrar
					</button>
				</form>
			</div>
		</section>

	</main>
</template>
<script>
import { mapActions } from 'vuex';

export default {
	methods: {
		...mapActions('User', ['login']),
		async handleSubmit() {
			let formData = this.$root.getAllData(this.$refs.form);

			if (this.$refs.form.checkValidity()) {
				try {
					const response = await this.login(formData);
					sessionStorage.setItem('eurest_url_cartao', window.location.href);
					this.$router.push({ name: 'home' });
				} catch (error) {
					console.error(error);
				}
			} else {
				this.$refs.form.reportValidity();
			}
		}
	},
	mounted() {
		sessionStorage.removeItem('apprest_token');
		this.unit = this.$route.params.unit;
	},
	data() {
		return {
			unit: null,
		}
	}
}
</script>